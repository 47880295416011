import React, {useRef, useEffect} from 'react'
import {gsap} from 'gsap'
import {motion} from "framer-motion";

import RabbitHole from './rabbit-hole'
import Hero from './../../components/Hero'
import Header from './../../components/Header'
import Footer from './../../components/Footer'

import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Index() {

  let titleAnim = useRef(null)
  let subtitleAnim = useRef(null)
  let tl = gsap.timeline()
  const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

  useEffect(() => {

    tl.from(subtitleAnim, {
      y: 15,
      opacity: 0,
      delay: 1,
      duration: .5,
      ease: "power3.inOut"
    })
    .from(titleAnim, {
      y: 15,
      opacity: 0,
      duration: .5,
      ease: "power3.inOut"
    })
    .from(".scroll-down", {
      y: 15,
      opacity: 0,
      duration: .5,
      ease: "power3.inOut"
    });

  }, [titleAnim, subtitleAnim])

  return(
    <div>
      <motion.div
        initial={{x:0}}
        animate={{x:'-100%'}}
        exit={{x:0}}
        transition={LoadingTransition}
        className="page-trans">
      </motion.div>
      <Header />
      <Hero>
        <h6 ref={el => subtitleAnim = el} className="sub-title">Playground</h6>
        <h1 ref={el => titleAnim = el} className="big-title">Rabbit Hole</h1>
      </Hero>
      <div style={{position: 'relative'}}>
        <RabbitHole />
      </div>
      <Footer />
    </div>

  );
}

export default Index;
