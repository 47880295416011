import React, { useRef, useState, useEffect } from "react";

import "./elements/styles.scss";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "./elements/Sequence";

const Fly = () => {
  const ref = useRef();
  const [isMobile, setMobile] = useState(window.innerWidth > 480);
  const updateMedia = () => {
    setMobile(window.innerWidth > 480);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div className="Sequence" data-black-overlay="1">
    {isMobile ? (
      <Controller>
        <Scene duration="500%" triggerHook="onLeave" pin>
          {progress => (
            <div style={{ height: "100vh", position: "relative" }}>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}
        </Scene>
      </Controller>
  ) : (
    <Controller>
      <Scene duration="1300%" triggerHook="onLeave" pin>
        {progress => (
          <div style={{ height: "100vh", position: "relative" }}>
            <Sequence ref={ref} progress={progress} />
          </div>
        )}
      </Scene>
    </Controller>
    )}
    </div>
  );
};

export default Fly;
