import React from 'react'
import {useLocation, Link} from 'react-router-dom'

function Menu() {
    const location = useLocation();
    return (
      <ul className="main-menu">
          <li><Link to="/">Home</Link>
          </li>
          <li className="has-droupdown"><Link to="#playground">Playground</Link>
              <ul className="submenu">
                  <li><Link to="/sequence">Image Sequence</Link></li>
                  <li><Link to="/rabbit-hole">Rabbit Hole</Link></li>
                  <li><Link to="/spring-parallax">Spring Parallax</Link></li>
                  <li><Link to="/spring-scroll">Spring Scroll</Link></li>
                  <li><Link to="/section-wipes">Section Wipes</Link></li>
                  <li><Link to="/split-text">Split Text</Link></li>
                  <li><Link to="/deck">Dealing Cards</Link></li>
              </ul>
          </li>
          <li><Link to="/contact" >Contact</Link></li>
      </ul>
    )

}

export default Menu
