import React from 'react'

import Form from '../elements/Form/Form'

function ContactBody() {

    const date = new Date().getFullYear()

    const socialLinks = [
        {
            name: 'Facebook',
            url: 'https://www.facebook.com/'
        },
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/'
        },
        {
            name: 'Twitter',
            url: 'https://www.twitter.com/'
        }
    ]

    return(
        <section id="contactSection" className="section">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 contact-left">
                        <h2 className="big-title anim-bot">Say hello!</h2>
                        <div className="row">

                            <div className="col-12 col-sm-6 anim-bot">
                                <h5 className="sub-title">Get in touch</h5>
                                <ul>
                                    <li>+44 33 3335 5671</li>
                                    <li>info@animus.email</li>
                                </ul>
                            </div>

                            <div className="col-12 col-sm-6 anim-bot">
                                <h5 className="sub-title">Visit Us</h5>
                                <ul>
                                  <li>71-75 Shelton Street</li>
                                  <li>Covent Garden London</li>
                                  <li>WC2H 9JQ</li>
                                </ul>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 copyright">
                                  <p style={{color: '#fff !important'}}>Copyright © {date} <span style={{fontFamily: 'Beckman', fontWeight: '900', fontSize: '1.5rem'}}>𝝠nimus</span></p>
                                <div className="footer-menu">
                                    <ul>
                                        {socialLinks.map((item, i) => <li key={i}><a href={item.url}>{item.name}</a></li>)}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contact-right anim-right">
                        <Form />
                    </div>

                </div>
            </div>
        </section>
    )

}

export default ContactBody
