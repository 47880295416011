// @flow
import React from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { SplitChars } from 'react-gsap';

const SplitTextStyled = styled.div`
  overflow: hidden;
  text-align: center;

  .section {
    height: 100vh;
  }
  .textContainer {
    position: relative;
    display: inline-block;
    transform-origin: center;
  }
  .text, .text2 {
    position: relative;
    font-size: 80px;
    font-weight: bold;
    display: inline-block;
  }
`;

const Chars = 'abc';

const Split = () => (
  <SplitTextStyled>
    <div className="section" />
    <Controller>
      <Scene
        pin={false}
        reverse={true}
        duration={500}
        offset={-300}
      >
        <Tween from={{ x: '200px' }} stagger={0.1}>
          <SplitChars
            wrapper={<div style={{ display: 'inline-block', fontSize: '40px' }} />}
          >
            This text gets splitted by chars.
          </SplitChars>
        </Tween>
      </Scene>
      <div className="section" />
    </Controller>
    <div className="section" />
  </SplitTextStyled>
);

export default Split;
