import A0 from "./fly0000.webp";
import A1 from "./fly0001.webp";
import A2 from "./fly0002.webp";
import A3 from "./fly0003.webp";
import A4 from "./fly0004.webp";
import A5 from "./fly0005.webp";
import A6 from "./fly0006.webp";
import A7 from "./fly0007.webp";
import A8 from "./fly0008.webp";
import A9 from "./fly0009.webp";

import A10 from "./fly0010.webp";
import A11 from "./fly0011.webp";
import A12 from "./fly0012.webp";
import A13 from "./fly0013.webp";
import A14 from "./fly0014.webp";
import A15 from "./fly0015.webp";
import A16 from "./fly0016.webp";
import A17 from "./fly0017.webp";
import A18 from "./fly0018.webp";
import A19 from "./fly0019.webp";

import A20 from "./fly0020.webp";
import A21 from "./fly0021.webp";
import A22 from "./fly0022.webp";
import A23 from "./fly0023.webp";
import A24 from "./fly0024.webp";
import A25 from "./fly0025.webp";
import A26 from "./fly0026.webp";
import A27 from "./fly0027.webp";
import A28 from "./fly0028.webp";
import A29 from "./fly0029.webp";

import A30 from "./fly0030.webp";
import A31 from "./fly0031.webp";
import A32 from "./fly0032.webp";
import A33 from "./fly0033.webp";
import A34 from "./fly0034.webp";
import A35 from "./fly0035.webp";
import A36 from "./fly0036.webp";
import A37 from "./fly0037.webp";
import A38 from "./fly0038.webp";
import A39 from "./fly0039.webp";

import A40 from "./fly0040.webp";
import A41 from "./fly0041.webp";
import A42 from "./fly0042.webp";
import A43 from "./fly0043.webp";
import A44 from "./fly0044.webp";
import A45 from "./fly0045.webp";
import A46 from "./fly0046.webp";
import A47 from "./fly0047.webp";
import A48 from "./fly0048.webp";
import A49 from "./fly0049.webp";

import A50 from "./fly0050.webp";
import A51 from "./fly0051.webp";
import A52 from "./fly0052.webp";
import A53 from "./fly0053.webp";
import A54 from "./fly0054.webp";
import A55 from "./fly0055.webp";
import A56 from "./fly0056.webp";
import A57 from "./fly0057.webp";
import A58 from "./fly0058.webp";
import A59 from "./fly0059.webp";

import A60 from "./fly0060.webp";
import A61 from "./fly0061.webp";
import A62 from "./fly0062.webp";
import A63 from "./fly0063.webp";
import A64 from "./fly0064.webp";
import A65 from "./fly0065.webp";
import A66 from "./fly0066.webp";
import A67 from "./fly0067.webp";
import A68 from "./fly0068.webp";
import A69 from "./fly0069.webp";

import A70 from "./fly0070.webp";
import A71 from "./fly0071.webp";
import A72 from "./fly0072.webp";
import A73 from "./fly0073.webp";
import A74 from "./fly0074.webp";
import A75 from "./fly0075.webp";
import A76 from "./fly0076.webp";
import A77 from "./fly0077.webp";
import A78 from "./fly0078.webp";
import A79 from "./fly0079.webp";

import A80 from "./fly0080.webp";
import A81 from "./fly0081.webp";
import A82 from "./fly0082.webp";
import A83 from "./fly0083.webp";
import A84 from "./fly0084.webp";
import A85 from "./fly0085.webp";
import A86 from "./fly0086.webp";
import A87 from "./fly0087.webp";
import A88 from "./fly0088.webp";
import A89 from "./fly0089.webp";

import A90 from "./fly0090.webp";
import A91 from "./fly0091.webp";
import A92 from "./fly0092.webp";
import A93 from "./fly0093.webp";
import A94 from "./fly0094.webp";
import A95 from "./fly0095.webp";
import A96 from "./fly0096.webp";
import A97 from "./fly0097.webp";
import A98 from "./fly0098.webp";
import A99 from "./fly0099.webp";

import A100 from "./fly0100.webp";
import A101 from "./fly0101.webp";
import A102 from "./fly0102.webp";
import A103 from "./fly0103.webp";
import A104 from "./fly0104.webp";
import A105 from "./fly0105.webp";
import A106 from "./fly0106.webp";
import A107 from "./fly0107.webp";
import A108 from "./fly0108.webp";
import A109 from "./fly0109.webp";

import A110 from "./fly0110.webp";
import A111 from "./fly0111.webp";
import A112 from "./fly0112.webp";
import A113 from "./fly0113.webp";
import A114 from "./fly0114.webp";
import A115 from "./fly0115.webp";
import A116 from "./fly0116.webp";
import A117 from "./fly0117.webp";
import A118 from "./fly0118.webp";
import A119 from "./fly0119.webp";

import A120 from "./fly0120.webp";
import A121 from "./fly0121.webp";
import A122 from "./fly0122.webp";
import A123 from "./fly0123.webp";
import A124 from "./fly0124.webp";
import A125 from "./fly0125.webp";
import A126 from "./fly0126.webp";
import A127 from "./fly0127.webp";
import A128 from "./fly0128.webp";
import A129 from "./fly0129.webp";

import A130 from "./fly0130.webp";
import A131 from "./fly0131.webp";
import A132 from "./fly0132.webp";
import A133 from "./fly0133.webp";
import A134 from "./fly0134.webp";
import A135 from "./fly0135.webp";
import A136 from "./fly0136.webp";
import A137 from "./fly0137.webp";
import A138 from "./fly0138.webp";
import A139 from "./fly0139.webp";

import A140 from "./fly0140.webp";
import A141 from "./fly0141.webp";
import A142 from "./fly0142.webp";
import A143 from "./fly0143.webp";
import A144 from "./fly0144.webp";
import A145 from "./fly0145.webp";
import A146 from "./fly0146.webp";
import A147 from "./fly0147.webp";
import A148 from "./fly0148.webp";
import A149 from "./fly0149.webp";

import A150 from "./fly0150.webp";
import A151 from "./fly0151.webp";
import A152 from "./fly0152.webp";
import A153 from "./fly0153.webp";
import A154 from "./fly0154.webp";
import A155 from "./fly0155.webp";
import A156 from "./fly0156.webp";
import A157 from "./fly0157.webp";
import A158 from "./fly0158.webp";
import A159 from "./fly0159.webp";

import A160 from "./fly0160.webp";
import A161 from "./fly0161.webp";
import A162 from "./fly0162.webp";
import A163 from "./fly0163.webp";
import A164 from "./fly0164.webp";
import A165 from "./fly0165.webp";
import A166 from "./fly0166.webp";
import A167 from "./fly0167.webp";
import A168 from "./fly0168.webp";
import A169 from "./fly0169.webp";

import A170 from "./fly0170.webp";
import A171 from "./fly0171.webp";
import A172 from "./fly0172.webp";
import A173 from "./fly0173.webp";
import A174 from "./fly0174.webp";
import A175 from "./fly0175.webp";
import A176 from "./fly0176.webp";

import A177 from "./fly0177.webp";
import A178 from "./fly0178.webp";
import A179 from "./fly0179.webp";
import A180 from "./fly0180.webp";
import A181 from "./fly0181.webp";
import A182 from "./fly0182.webp";
import A183 from "./fly0183.webp";
import A184 from "./fly0184.webp";
import A185 from "./fly0185.webp";
import A186 from "./fly0186.webp";
import A187 from "./fly0187.webp";
import A188 from "./fly0188.webp";
import A189 from "./fly0189.webp";
import A190 from "./fly0190.webp";
import A191 from "./fly0191.webp";
import A192 from "./fly0192.webp";
import A193 from "./fly0193.webp";
import A194 from "./fly0194.webp";
import A195 from "./fly0195.webp";
import A196 from "./fly0196.webp";
import A197 from "./fly0197.webp";
import A198 from "./fly0198.webp";
import A199 from "./fly0199.webp";
import A200 from "./fly0200.webp";
import A201 from "./fly0201.webp";
import A202 from "./fly0202.webp";
import A203 from "./fly0203.webp";
import A204 from "./fly0204.webp";
import A205 from "./fly0205.webp";
import A206 from "./fly0206.webp";
import A207 from "./fly0207.webp";
import A208 from "./fly0208.webp";
import A209 from "./fly0209.webp";
import A210 from "./fly0210.webp";
import A211 from "./fly0211.webp";
import A212 from "./fly0212.webp";
import A213 from "./fly0213.webp";
import A214 from "./fly0214.webp";
import A215 from "./fly0215.webp";
import A216 from "./fly0216.webp";
import A217 from "./fly0217.webp";
import A218 from "./fly0218.webp";
import A219 from "./fly0219.webp";
import A220 from "./fly0220.webp";
import A221 from "./fly0221.webp";
import A222 from "./fly0222.webp";
import A223 from "./fly0223.webp";
import A224 from "./fly0224.webp";
import A225 from "./fly0225.webp";
import A226 from "./fly0226.webp";
import A227 from "./fly0227.webp";
import A228 from "./fly0228.webp";
import A229 from "./fly0229.webp";
import A230 from "./fly0230.webp";
import A231 from "./fly0231.webp";
import A232 from "./fly0232.webp";
import A233 from "./fly0233.webp";
import A234 from "./fly0234.webp";
import A235 from "./fly0235.webp";
import A236 from "./fly0236.webp";
import A237 from "./fly0237.webp";
import A238 from "./fly0238.webp";
import A239 from "./fly0239.webp";
import A240 from "./fly0240.webp";
import A241 from "./fly0241.webp";
import A242 from "./fly0242.webp";
import A243 from "./fly0243.webp";
import A244 from "./fly0244.webp";
import A245 from "./fly0245.webp";
import A246 from "./fly0246.webp";
import A247 from "./fly0247.webp";
import A248 from "./fly0248.webp";
import A249 from "./fly0249.webp";
import A250 from "./fly0250.webp";
import A251 from "./fly0251.webp";
import A252 from "./fly0252.webp";
import A253 from "./fly0253.webp";
import A254 from "./fly0254.webp";
import A255 from "./fly0255.webp";
import A256 from "./fly0256.webp";
import A257 from "./fly0257.webp";
import A258 from "./fly0258.webp";
import A259 from "./fly0259.webp";
import A260 from "./fly0260.webp";
import A261 from "./fly0261.webp";
import A262 from "./fly0262.webp";
import A263 from "./fly0263.webp";
import A264 from "./fly0264.webp";
import A265 from "./fly0265.webp";
import A266 from "./fly0266.webp";
import A267 from "./fly0267.webp";
import A268 from "./fly0268.webp";
import A269 from "./fly0269.webp";
import A270 from "./fly0270.webp";
import A271 from "./fly0271.webp";
import A272 from "./fly0272.webp";
import A273 from "./fly0273.webp";
import A274 from "./fly0274.webp";
import A275 from "./fly0275.webp";
import A276 from "./fly0276.webp";
import A277 from "./fly0277.webp";
import A278 from "./fly0278.webp";
import A279 from "./fly0279.webp";
import A280 from "./fly0280.webp";
import A281 from "./fly0281.webp";
import A282 from "./fly0282.webp";
import A283 from "./fly0283.webp";
import A284 from "./fly0284.webp";
import A285 from "./fly0285.webp";
import A286 from "./fly0286.webp";
import A287 from "./fly0287.webp";
import A288 from "./fly0288.webp";
import A289 from "./fly0289.webp";
import A290 from "./fly0290.webp";
import A291 from "./fly0291.webp";
import A292 from "./fly0291.webp";
import A293 from "./fly0291.webp";
import A294 from "./fly0291.webp";
import A295 from "./fly0291.webp";
import A296 from "./fly0291.webp";
import A297 from "./fly0291.webp";
import A298 from "./fly0291.webp";
import A299 from "./fly0291.webp";
import A300 from "./fly0291.webp";

export const Fly = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

  A100,
  A101,
  A102,
  A103,
  A104,
  A105,
  A106,
  A107,
  A108,
  A109,

  A110,
  A111,
  A112,
  A113,
  A114,
  A115,
  A116,
  A117,
  A118,
  A119,

  A120,
  A121,
  A122,
  A123,
  A124,
  A125,
  A126,
  A127,
  A128,
  A129,

  A130,
  A131,
  A132,
  A133,
  A134,
  A135,
  A136,
  A137,
  A138,
  A139,

  A140,
  A141,
  A142,
  A143,
  A144,
  A145,
  A146,
  A147,
  A148,
  A149,

  A150,
  A151,
  A152,
  A153,
  A154,
  A155,
  A156,
  A157,
  A158,
  A159,

  A160,
  A161,
  A162,
  A163,
  A164,
  A165,
  A166,
  A167,
  A168,
  A169,

  A170,
  A171,
  A172,
  A173,
  A174,
  A175,
  A176,
  A177,
A178,
A179,
A180,
A181,
A182,
A183,
A184,
A185,
A186,
A187,
A188,
A189,
A190,
A191,
A192,
A193,
A194,
A195,
A196,
A197,
A198,
A199,
A200,
A201,
A202,
A203,
A204,
A205,
A206,
A207,
A208,
A209,
A210,
A211,
A212,
A213,
A214,
A215,
A216,
A217,
A218,
A219,
A220,
A221,
A222,
A223,
A224,
A225,
A226,
A227,
A228,
A229,
A230,
A231,
A232,
A233,
A234,
A235,
A236,
A237,
A238,
A239,
A240,
A241,
A242,
A243,
A244,
A245,
A246,
A247,
A248,
A249,
A250,
A251,
A252,
A253,
A254,
A255,
A256,
A257,
A258,
A259,
A260,
A261,
A262,
A263,
A264,
A265,
A266,
A267,
A268,
A269,
A270,
A271,
A272,
A273,
A274,
A275,
A276,
A277,
A278,
A279,
A280,
A281,
A282,
A283,
A284,
A285,
A286,
A287,
A288,
A289,
A290,
A291,
A292,
A293,
A294,
A295,
A296,
A297,
A298,
A299,
A300,
);
