import React from 'react'
import {Link} from 'react-router-dom'

function Logo(){

    return(
        <div className="logo">
            <Link to='/'><p style={{margin: 'auto'}}><span style={{fontFamily: 'Beckman', fontWeight: '900', fontSize: '2rem'}}>𝝠nimus</span></p></Link>
        </div>
    )

}

export default Logo
